import ApiHelper from '~/helpers/ApiHelper';

const apiHelper = new ApiHelper();

export default class UserService {
	static ADMIN_PROFILE = 1;
	static CUSTOMER_PROFILE = 2;
	static ORDER_MANAGER = 3;

	static COMMON_MEMBER = 1;
	static COLLABORATOR_MEMBER = 2;
	static NON_MEMBER = 3;
	static SHOP = 4;

	axios;
	fields = [
		'name',
		'surnames',
		'email',
		'phone',
		'dni',
		'all_consum_groups',
		'consumption_group_id',
		'consumption_group_manage',
		'consumption_group_name',
		'addresses_list',
		'city_id',
		'address',
		'cp',
		'customer_type_id',
		'password',
		'password_confirmation',
		'local_coin',
		'policy',
		'newsletter',
		'activated',
	];

	constructor(axios) {
		this.axios = axios;
	}

	list(pagination, filters, order) {
		const params = apiHelper.setQueryParams(pagination, filters, order);
		return this.axios.get("/admin/customer", { params: params });
	}

	get(id) {
		return this.axios.get(`/customer/${id}`);
	}
	getSelectorList() {
		return this.axios.get(`/admin/customer/filter?sort=name`);
	}

	store(user) {
		const data = apiHelper.buildParams(this.fields, user);
		return this.axios.post(`/admin/customer`, data);
	}

	register(user) {
		const data = apiHelper.buildParams(this.fields, user);
		return this.axios.post(`/customer`, data);
	}

	update(user) {
		const data = apiHelper.buildParams(this.fields, user);
		return this.axios.put(`/admin/customer/${user.id}`, data);
	}

	delete(id) {
		return this.axios.delete(`/admin/customer/${id}`);
	}

	updateProfile(user) {
		const data = apiHelper.buildParams(this.fields, user);
		return this.axios.put(`/customer/${user.id}`, data);
	}

	changePassword(model) {
		const data = {
			password: model.password,
			confirm_password: model.confirm_password,
		};
		return this.axios.post(`/v1/changePassword`, data);
	}

	changePasswordByAdmin(model) {
		const data = {
			user_id: model.user_id,
			password: model.password,
			confirm_password: model.confirm_password,
		};
		return this.axios.post(`/admin/customer/changePassword`, data);
	}

	passwordRecovery(email) {
		const data = {
			email: email,
		};
		return this.axios.post('/v1/passwordRecovery', data);
	}

	passwordReset(model) {
		const data = {
			email: model.email,
			password: model.password,
			password_confirmation: model.password_confirmation,
			token: model.token,
		};
		return this.axios.post('/v1/passwordReset', data);
	}
}
