import Vue from 'vue';
import { extend } from 'vee-validate';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, alpha, email, length, numeric } from 'vee-validate/dist/rules';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

extend('required', {
	...required,
	message: 'Este campo es obligatorio',
});

extend('length', {
	...length,
	message: 'El teléfono debe tener 9 dígitos',
});

extend('numeric', {
	...numeric,
	message: 'Este campo sólo puede contener números',
});

extend('alpha', {
	...alpha,
	message: 'Este campo sólo puede contener letras',
});

extend('email', email);
