//
//
//
//
//
//
//
//

import { ShoppingBagIcon } from 'vue-feather-icons';
export default {
	name: 'ShoppingCart',
	components: { ShoppingBagIcon },
	computed: {
		current_products() {
			return this.$store.state.shopping_cart_products;
		},
	},
};
