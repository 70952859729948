//
//
//
//
//
//
//
//
//
//
//
//

import Header from '~/components/blocks/Header';
import NavbarAdmin from '~/components/blocks/NavbarAdmin';
import Footer from '~/components/blocks/Footer';
import Alert from '../components/core/Alert';

export default {
	name: 'AdminLayout',
	components: {
		Alert,
		Header,
		NavbarAdmin,
		Footer,
	},
};
