//
//
//
//
//
//
//
//
//
//
//

import Header from '~/components/blocks/Header';
import Footer from '~/components/blocks/Footer';
import Alert from '../components/core/Alert';

export default {
	name: 'AppLayout',
	components: {
		Alert,
		Header,
		Footer,
	},
};
