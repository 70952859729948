
export default function({ $auth }) {
	$auth.onError((error, name, endpoint) => {
		// TODO Esto se podría usar para controlar cuando caduque el token y reidirigir de nuevo al login
	})

	$auth.onRedirect((to, from) => {
		console.error(to)
		// TODO esto se podría usar para controlar la navegación
		// you can optionally change `to` by returning a new value
	})
}