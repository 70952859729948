//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UserIcon, ShoppingBagIcon } from 'vue-feather-icons';
import ShoppingCart from '../core/ShoppingCart';
import UserService from '../../services/UserService';
export default {
	name: 'Header',
	components: {
		ShoppingCart,
		UserIcon,
		ShoppingBagIcon,
	},

	data() {
		return {
			profiles: [],
		};
	},

	computed: {
		loggedUser() {
			return this.$store.getters.loggedUser;
		},
		loggedUserAuth() {
			return this.$auth.$state.user;
		},
		isAdmin() {
			return (
				this.$auth.$state.user && this.$auth.$state.user.profile_id === UserService.ADMIN_PROFILE
			);
		},
		isOrderManager() {
			return (
				this.$auth.$state.user && this.$auth.$state.user.profile_id === UserService.ORDER_MANAGER
			);
		},
		isMember() {
			return (
				(this.$auth.$state.user &&
					this.$auth.$state.user.profile_id === UserService.CUSTOMER_PROFILE &&
					this.$auth.$state.user.customer_type !== UserService.NON_MEMBER &&
					this.$auth.$state.user.customer_type !== UserService.SHOP) ||
				(this.customer_type_id && this.customer_type_id !== UserService.NON_MEMBER && this.customer_type_id !== UserService.SHOP)
			);
		},
		isCommonMember() {
			return (
				this.$auth.$state.user &&
				this.$auth.$state.user.profile_id === UserService.CUSTOMER_PROFILE &&
				this.$auth.$state.user.customer_type === UserService.COMMON_MEMBER
			);
		},
		isNonMember() {
			return (
				this.$auth.$state.user &&
				this.$auth.$state.user.profile_id === UserService.CUSTOMER_PROFILE &&
				this.$auth.$state.user.customer_type === UserService.NON_MEMBER
			);
		},
		isShop() {
			return (
				this.$auth.$state.user &&
				this.$auth.$state.user.profile_id === UserService.CUSTOMER_PROFILE &&
				this.$auth.$state.user.customer_type === UserService.SHOP
			);
		},
	},

	methods: {
		LogOut() {
			this.$auth.logout();
			this.$store.commit('removeToken');
			if (this.$router.currentRoute.path !== '/') {
				this.$router.push('/');
			}
		},
	},
};
