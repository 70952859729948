//
//
//
//
//
//
//

import Alert from '../components/core/Alert';
export default {
	name: 'LoginLayout',
	components: { Alert },
};
