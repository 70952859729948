import { render, staticRenderFns } from "./AdminLayout.vue?vue&type=template&id=63d14c66&"
import script from "./AdminLayout.vue?vue&type=script&lang=js&"
export * from "./AdminLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Alert: require('/home/runner/work/lareverde-shop/lareverde-shop/components/core/Alert.vue').default,Header: require('/home/runner/work/lareverde-shop/lareverde-shop/components/blocks/Header.vue').default,NavbarAdmin: require('/home/runner/work/lareverde-shop/lareverde-shop/components/blocks/NavbarAdmin.vue').default,Footer: require('/home/runner/work/lareverde-shop/lareverde-shop/components/blocks/Footer.vue').default})
