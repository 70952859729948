//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'Alert',
	methods: {
		onHide(event) {
			this.$store.commit('setAlert', {
				show: false,
				message: '',
				type: '',
			});
		},
	},
	computed: {
		showAlert() {
			return this.$store.state.alert;
		},
	},
};
