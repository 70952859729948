//
//
//
//
//
//
//
//
//

import {
	UsersIcon,
	ShoppingBagIcon,
	ClipboardIcon,
	MapPinIcon,
	TrendingUpIcon,
	SlidersIcon,
	FileIcon,
	SettingsIcon,
} from 'vue-feather-icons';
import UserService from '../../services/UserService';

export default {
	components: {
		UsersIcon,
		ShoppingBagIcon,
		ClipboardIcon,
		MapPinIcon,
		TrendingUpIcon,
		SlidersIcon,
		FileIcon,
		SettingsIcon,
	},

	data() {
		return {
			menu: [],
		};
	},
	mounted() {
		if (this.isAdmin()) {
			this.menu = [
				{
					to: '/administracion/usuarios',
					title: 'Usuarios',
					icon: UsersIcon,
				},
				{
					to: '/administracion/productos',
					title: 'Productos',
					icon: ShoppingBagIcon,
				},
				{
					to: '/administracion/pedidos',
					title: 'Pedidos',
					icon: ClipboardIcon,
				},
				{
					to: '/administracion/puntos-recogida',
					title: 'Recogida',
					icon: MapPinIcon,
				},
				{
					to: '/administracion/saldos',
					title: 'Saldos',
					icon: TrendingUpIcon,
				},
				{
					to: '/administracion/consumo-minimo',
					title: 'Consumos',
					icon: SlidersIcon,
				},
				{
					to: '/administracion/grupos-consumo',
					title: 'G. Consumo',
					icon: UsersIcon,
				},
				{
					to: '/administracion/cooperativa',
					title: 'Documentación',
					icon: FileIcon,
				},
				{
					to: '/administracion/configuracion',
					title: 'Configuración',
					icon: SettingsIcon,
				},
			];
		} else if (this.isOrderManager()) {
			this.menu = [
				{
					to: '/administracion/pedidos',
					title: 'Pedidos',
					icon: ClipboardIcon,
				},
			];
		}
		console.log(this.isAdmin());
	},
	methods: {
		isAdmin() {
			return (
				this.$auth.$state.user && this.$auth.$state.user.profile_id === UserService.ADMIN_PROFILE
			);
		},
		isOrderManager() {
			return (
				this.$auth.$state.user && this.$auth.$state.user.profile_id === UserService.ORDER_MANAGER
			);
		},
	},
};
