export default class ApiHelper {
	setQueryParams(pagination, filters, order) {
		const params = new URLSearchParams();
		if (pagination) {
			params.append('page', pagination.page);
			params.append('pagination', pagination.itemsPerPage);
		}
		if (order.sortBy) {
			if (order.sortDesc) {
				params.append('sort', '-' + order.sortBy);
			} else {
				params.append('sort', order.sortBy);
			}
		}
		if (Object.keys(filters).length) {
			Object.keys(filters).forEach(key => {
				if (filters[key] !== undefined && filters[key] !== null && !Array.isArray(filters[key])) {
					params.append(key, filters[key]);
				} else if (filters[key] !== undefined && filters[key] !== null && Array.isArray(filters[key])) {
					for (const index in filters[key]) {
						let arrayKey = key + '[' + index + ']';
						params.append(arrayKey, filters[key][index]);
					}
				}
			});
		}
		return params;
	}
	buildParams(fields, model) {
		const params = {};
		for (const field of fields) {
			if (model[field] != undefined && !Array.isArray(model[field]) && typeof model[field] !== 'object') {
				params[field] = model[field];
			} else if (model[field] != undefined && (Array.isArray(model[field]) || typeof model[field] === 'object')) {
				params[field] = JSON.stringify(model[field]);
			}
		}
		return params;
	}

	buildParamsWithFile(fields, model) {
		const formData = new FormData();
		for (const field of fields) {
			if (model[field] != undefined && !Array.isArray(model[field]) && (typeof model[field] !== 'object' || typeof model[field].lastModified  === 'number')) {
				formData.append(field, model[field]);
			} else if (model[field] != undefined && (Array.isArray(model[field]) || typeof model[field] === 'object')) {
				formData.append(field, JSON.stringify(model[field]));
			}
		}
		return formData;
	}

	handleErrors(error, formRef = {}, store, show_in_alert = false) {
		let message = '';
		if (error.response && error.response.status == 500) {
			if (error.response.data.error && typeof error.response.data.error == 'string') {
				message = error.response.data.error;
			} else if (error.config.method == 'get') {
				message = 'Se ha producido un error en el servidor al solicitar datos';
			} else if (error.config.method == 'post' || error.config.method == 'put') {
				message = 'Se ha producido un error en el servidor al guardar datos';
			}
		}
		if (error.response && error.response.status == 502) {
			message = 'No tienes permisos para realizar esta acción';
		}
		if (error.response && error.response.status == 503) {
			message = error.response.data;
		}
		if (error.response && error.response.status == 404) {
			message = 'El recurso solicitado no existe';
		}
		if (store && message) {
			store.commit('setAlert', {
				show: true,
				message: message,
				type: 'danger',
			});
		}
		if (
			error.response &&
			(error.response.status == 450 || error.response.status == 422) &&
			(Object.keys(formRef).length || show_in_alert)
		) {
			this.handleValitationErrors(formRef, error.response.data, error.response.status, store, show_in_alert);
		}
	}

	handleValitationErrors(ref, errorsFields, status, store, show_in_alert) {
		if (status == 422) {
			errorsFields = errorsFields['errors'];
		}
		Object.keys(errorsFields).forEach(key => {
			if (show_in_alert){
				store.commit('setAlert', {
					show: true,
					message: errorsFields[key][0],
					type: 'danger',
				});
			} else {
				if (ref.errors[key]) {
					ref.errors[key].push(errorsFields[key][0]);
				} else {
					ref.errors[key] = [];
					ref.errors[key].push(errorsFields[key][0]);
				}
			}
		});
		return ref;
	}

	filterHasChanged(pagination, order, filter, lastFilter) {
		// Si ha cambiado la paginación
		if (
			(pagination &&
				pagination.page &&
				(!Object.keys(lastFilter).length || lastFilter.pagination.page != pagination.page)) ||
			// Si ha cambiado el número de elementos por página
			(pagination &&
				pagination.itemsPerPage &&
				(!Object.keys(lastFilter).length || lastFilter.pagination.itemsPerPage != pagination.itemsPerPage)) ||
			// Si han cambiado los filtros
			!Object.keys(lastFilter).length ||
			JSON.stringify(filter) != JSON.stringify(lastFilter.filter) ||
			// Si ha cambiado la ordenación
			(order &&
				order.sortBy &&
				(!Object.keys(lastFilter).length ||
					JSON.stringify(lastFilter.order.sortBy) != JSON.stringify(order.sortBy) ||
					JSON.stringify(lastFilter.order.sortDesc) != JSON.stringify(order.sortDesc)))
		) {
			lastFilter.pagination = JSON.parse(JSON.stringify(pagination));
			lastFilter.order = JSON.parse(JSON.stringify(order));
			lastFilter.filter = JSON.parse(JSON.stringify(filter));
			return true;
		}
		return false;
	}
}
