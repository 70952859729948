import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
export const state = () => ({
	token: null,
	user: {},
	start_date: null,
	end_date: null,
	alert: {
		show: false,
		message: '',
		type: '',
	},
	shopping_cart_products: 0,
});

export const mutations = {
	setToken(state, token) {
		state.token = token;
		localStorage.setItem('token', token);
	},
	removeToken(state) {
		state.token = null;
		state.user = null;
		localStorage.clear();
	},
	setUser(state, user) {
		state.user = user;
	},
	startDate(state, date) {
		state.start_date = date;
	},
	endDate(state, date) {
		state.end_date = date;
	},
	setAlert(state, alert) {
		state.alert = alert;
	},
	updateShoppingCartProducts(state, data) {
		state.shopping_cart_products = data.products_quantity;
		localStorage.setItem('shoppingCart', JSON.stringify(data.current_cart));
	},
};

export const getters = {
	isAuthUser(state, getters) {
		return state.token !== null;
	},
	isNameUser(state, getters) {
		return state.user.name;
	},
	loggedUser(state, getters) {
		return state.user;
	},
	startDate(state, getters) {
		return state.start_date;
	},
	endDate(state, getters) {
		return state.end_date;
	},
	isAdmin: (state) => state.user.id == 1,
	show_alert(state, getters) {
		return state.alert;
	},
};
